/* eslint-disable no-shadow */
import axios from 'axios'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  'content-type': 'multipart/form-data',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

// eslint-disable-next-line import/prefer-default-export
export const fileUpload = params => new Promise((resolve, reject) => {
  const { file, type, related } = params
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', type)
  formData.append('related', related)
  axios
    .post(`${process.env.VUE_APP_API_URL}/file/upload`, formData, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})
