<template>
  <b-row>
    <b-col cols="12">
      <b-card-code
        :title="title"
        no-body
      >
        <b-card-body>
          <b-row class="mb-1">
            <b-table
              striped
              hover
              responsive
              :items="files"
              :fields="fields"
            >
              <template #cell(type)="row">
                <b-img
                  :src="iconType(row.item.type)"
                  class="iconType"
                />
              </template>
              <template #cell(name)="row">
                <b-link
                  :href="row.item.path"
                  target="_blank"
                  class="text-secondary"
                >
                  {{ row.item.name }}
                  <feather-icon
                    icon="ExternalLinkIcon"
                    size="12"
                  />
                </b-link>
              </template>
              <template #cell(action)="">
                <feather-icon
                  icon="Trash2Icon"
                  size="12"
                  class="text-danger"
                />
              </template>
            </b-table>
          </b-row>

          <hr class="w-100">
          <b-row>
            <b-col md="6">
              <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-col>
            <b-col
              md="12"
              class="mt-1"
            >
              <b-button
                type="submit"
                variant="primary"
                @click="submit"
              >
                Upload
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BButton, BCardBody, BFormFile, BLink, BImg, BTable,
} from 'bootstrap-vue'
import { fileUpload } from '@/services/file'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BButton,
    BCardBody,
    BFormFile,
    BLink,
    BImg,
    BTable,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    files: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      file: null,
      DocumentIcon: require('@/assets/images/upload/document.png'),
      ImageIcon: require('@/assets/images/upload/image.png'),
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'name', label: 'Name' },
        { key: 'action', label: '#' },
      ],
    }
  },
  methods: {
    submit() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          fileUpload({ file: this.file, type: this.type, related: this.$route.params.id })
            .then(() => {
              this.$store.commit('set_loading_action', false)
              this.$router.go(this.$router.currentRoute)
            })
            .catch(err => {
              this.$store.commit('set_loading_action', false)
              console.log(err)
            })
        }
      })
    },

    iconType(type) {
      let iconType = ''
      if (type === 'jpg' || type === 'jpeg' || type === 'png') {
        iconType = this.ImageIcon
      } else if (type === 'pdf' || type === 'doc' || type === 'docx' || type === 'csv' || type === 'txt' || type === 'xlx' || type === 'xls' || type === 'xlsx') {
        iconType = this.DocumentIcon
      } else {
        iconType = ''
      }
      return iconType
    },
  },
}
</script>

<style>
  .iconType {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
</style>
