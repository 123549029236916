<template>
  <b-row>
    <b-col cols="12">
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>

          <b-form @submit.prevent="submit">
            <b-row>

              <!-- Logo -->
              <b-col md="6">
                <label>Logo</label>
                <b-form-group>
                  <label for="mc-logo">
                    <b-img
                      :src="company.logo ? company.logo : previewLogo"
                      class="previewImg"
                    />
                  </label>
                  <input
                    id="mc-logo"
                    type="file"
                    accept="image/*"
                    style="display:none"
                    @change="selectLogo"
                  >
                </b-form-group>
              </b-col>

              <b-col md="6" />

              <!-- Name -->
              <b-col md="6">
                <b-form-group
                  label="Name"
                  label-for="mc-name"
                >
                  <b-form-input
                    id="mc-name"
                    v-model="company.name"
                    placeholder="Name"
                  />
                </b-form-group>
              </b-col>

              <!-- Legal Name -->
              <b-col md="6">
                <b-form-group
                  label="Legal Name"
                  label-for="mc-legal_name"
                >
                  <b-form-input
                    id="mc-legal_name"
                    v-model="company.legal_name"
                    placeholder="Legal Name"
                  />
                </b-form-group>
              </b-col>

              <!-- SSN -->
              <b-col md="6">
                <b-form-group
                  label="SSN"
                  label-for="mc-ssn"
                >
                  <b-form-input
                    id="mc-ssn"
                    v-model="company.ssn"
                    placeholder="SSN"
                  />
                </b-form-group>
              </b-col>

              <!-- EIN -->
              <b-col md="6">
                <b-form-group
                  label="EIN"
                  label-for="mc-ein"
                >
                  <b-form-input
                    id="mc-ein"
                    v-model="company.ein"
                    placeholder="EIN"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Type -->
              <b-col md="6">
                <b-form-group
                  label="Type"
                  label-for="mc-type"
                >
                  <v-select
                    id="mc-type"
                    v-model="company.type"
                    :options="companyTypes"
                    placeholder="Type"
                    class="w-100"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Email -->
              <b-col md="6">
                <b-form-group
                  label="Email"
                  label-for="mc-email"
                >
                  <b-form-input
                    id="mc-email"
                    v-model="company.email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>

              <!-- Mailing Address -->
              <b-col md="6">
                <b-form-group
                  label="Mailing Address"
                  label-for="mc-maling_address"
                >
                  <b-form-input
                    id="mc-maling_address"
                    v-model="company.maling_address"
                    placeholder="Mailing Address"
                  />
                </b-form-group>
              </b-col>

              <!-- Domain -->
              <b-col md="6">
                <b-form-group
                  label="Domain"
                  label-for="mc-domain"
                >
                  <b-form-input
                    id="mc-domain"
                    v-model="company.domain"
                    placeholder="Domain"
                  />
                </b-form-group>
              </b-col>

              <!-- Phone -->
              <b-col md="6">
                <b-form-group
                  label="Phone"
                  label-for="mc-phone"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      US (+1)
                    </b-input-group-prepend>
                    <cleave
                      id="mc-phone"
                      v-model="company.phone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="1234 567 8900"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Fax -->
              <b-col md="6">
                <b-form-group
                  label="Fax"
                  label-for="mc-fax"
                >
                  <b-form-input
                    id="mc-fax"
                    v-model="company.fax"
                    placeholder="Fax"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Address -->
              <b-col md="6">
                <b-form-group
                  label="Address"
                  label-for="mc-address"
                >
                  <b-form-input
                    id="mc-address"
                    v-model="company.address"
                    placeholder="Address"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- SCAC Code -->
              <b-col md="6">
                <b-form-group
                  label="SCAC Code"
                  label-for="mc-scac_code"
                >
                  <b-form-input
                    id="mc-scac_code"
                    v-model="company.scac_code"
                    placeholder="SCAC Code"
                  />
                </b-form-group>
              </b-col>

              <!-- DBA Name -->
              <b-col md="6">
                <b-form-group
                  label="DBA Name"
                  label-for="mc-dba_name"
                >
                  <b-form-input
                    id="mc-dba_name"
                    v-model="company.dba_name"
                    placeholder="DBA Name"
                  />
                </b-form-group>
              </b-col>

              <!-- US Dot Number -->
              <b-col md="6">
                <b-form-group
                  label="US Dot Number"
                  label-for="mc-usdot"
                >
                  <b-form-input
                    id="mc-usdot"
                    v-model="company.usdot"
                    placeholder="US Dot Number"
                  />
                </b-form-group>
              </b-col>

              <!-- MC/MX/FF -->
              <b-col md="6">
                <b-form-group
                  label="MC/MX/FF"
                  label-for="mc-operating_authority"
                >
                  <b-form-input
                    id="mc-operating_authority"
                    v-model="company.operating_authority"
                    placeholder="MC/MX/FF"
                  />
                </b-form-group>
              </b-col>

              <!-- Insurance Expiring Date -->
              <b-col md="6">
                <b-form-group
                  label="Insurance Expiring Date"
                  label-for="mc-insurance_expire_date"
                >
                  <cleave
                    id="mc-insurance_expire_date"
                    v-model="company.insurance_expire_date"
                    :options="options.date"
                    class="form-control"
                    :raw="false"
                    placeholder="Insurance Expiring Date"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Client ID -->
              <b-col md="6">
                <b-form-group
                  label="QuickBooks Client ID"
                  label-for="mc-client_id"
                >
                  <b-form-input
                    id="mc-client_id"
                    v-model="company.client_id"
                    placeholder="QuickBooks Client ID"
                  />
                </b-form-group>
              </b-col>

              <!-- Client Secret -->
              <b-col md="6">
                <b-form-group
                  label="QuickBooks Client Secret"
                  label-for="mc-client_secret"
                >
                  <b-form-input
                    id="mc-client_secret"
                    v-model="company.client_secret"
                    placeholder="QuickBooks Client Secret"
                  />
                </b-form-group>
              </b-col>

              <!-- QuickBooks Company ID -->
              <b-col md="6">
                <b-form-group
                  label="QuickBooks Company ID"
                  label-for="mc-qboCompanyID"
                >
                  <b-form-input
                    id="mc-qboCompanyID"
                    v-model="company.qboCompanyID"
                    placeholder="QuickBooks Company ID"
                  />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card-code>
    </b-col>
    <b-col
      v-if="editId"
      md="12"
    >
      <file-upload-component
        :title="'Company Files'"
        :type="'company'"
        :files="files"
      />
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCardBody, BInputGroup, BInputGroupPrepend, BImg,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import vSelect from 'vue-select'
import FileUploadComponent from '@/components/FileUploadComponent.vue'
import * as companyService from '@/services/company'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardBody,
    vSelect,
    Cleave,
    BInputGroup,
    BInputGroupPrepend,
    BImg,
    FileUploadComponent,
  },
  data() {
    return {
      company: {
        name: '',
        domain: '',
        type: '',
        logo: null,
        legal_name: '',
        ssn: '',
        ein: '',
        email: '',
        phone: '',
        fax: '',
        address: '',
        maling_address: '',
        scac_code: '',
        dba_name: '',
        usdot: '',
        operating_authority: '',
        insurance_expire_date: '',
        client_id: '',
        client_secret: '',
        qboCompanyID: '',
      },
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      companyTypes: ['Carrier', 'Broker', 'Carrier/Broker/Freight Forwarder'],
      editId: '',
      previewLogo: require('@/assets/images/upload/1.png'),
      // eslint-disable-next-line no-undef
      files: [],
    }
  },
  created() {
    if (this.$route.name === 'edit-company') {
      this.editId = this.$route.params.id
      this.getCompanyById(this.$route.params.id)
    }
  },
  methods: {
    // store company
    submit() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          if (this.$route.name === 'add-company') {
            this.create()
          } else {
            this.update()
          }
        }
      })
    },

    create() {
      companyService.create({
        ...this.company,
        company_id: this.$store.state.auth.companyId,
      })
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'company' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    update() {
      companyService.update({
        ...this.company,
        id: this.editId,
        company_id: this.$store.state.auth.companyId,
      })
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: res.data.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(() => {
            this.$router.push({ name: 'company' })
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    // get company by id in edit
    getCompanyById(id) {
      this.$store.commit('set_loading_action', true)
      companyService.edit(id)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.company = res.company
          this.files = res.files
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          console.log(err)
        })
    },

    // select logo
    selectLogo(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.previewLogo = e.target.result
          this.company.logo = this.previewLogo
        }
        reader.readAsDataURL(input.files[0])
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .vs__dropdown-toggle {
    border: 1px solid #ebe9f1;
    max-height: 70px;
    overflow: auto;
  }
  .previewImg {
    height: 150px;
    object-fit: contain;
    cursor: pointer;
  }
</style>
